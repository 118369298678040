<script setup lang="ts">
import { useStatistics } from '@/components/composables/useStatistics';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiHcChevron_right } from '@primeinsightsgroupllc/prime-icons';
import { useRouter } from 'vue-router';
import type { StatisticItem } from '@/types';
import {
	STATISTICS_WRAPPER,
	STATISTICS_ITEM,
	STATISTICS_CONTENT,
	STATISTICS_ICON,
	STATISTICS_INFO,
	STATISTICS_TITLE,
	STATISTICS_DETAILS,
} from './testAttrs';

const { statistics } = useStatistics();
const router = useRouter();

const handleClick = (item: StatisticItem) => {
	if (item.route) {
		router.push(item.route);
	} else if (item.handler) {
		item.handler();
	}
};
</script>

<template>
	<div class="statistic-wrapper" :data-test="STATISTICS_WRAPPER">
		<div
			v-for="item in statistics.filter((i) => i.visible)"
			:key="item.title"
			class="statistic-item"
			:data-test="STATISTICS_ITEM"
			@click="handleClick(item)"
		>
			<div class="statistic-item__content" :data-test="STATISTICS_CONTENT">
				<component
					:is="item.icon"
					v-if="item.icon"
					color="base-primary"
					:data-test="STATISTICS_ICON"
				/>
				<div class="statistic-item__info" :data-test="STATISTICS_INFO">
					<PrimeText size="lg" weight="600" :data-test="STATISTICS_TITLE">
						{{ item.title }}
					</PrimeText>
					<PrimeText
						size="sm"
						weight="500"
						color="grey-500"
						:data-test="STATISTICS_DETAILS"
					>
						{{ item.details }}
					</PrimeText>
				</div>
			</div>
			<PiHcChevron_right />
		</div>
	</div>
</template>

<style scoped lang="scss">
.statistic {
	&-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
	}
	&-item {
		width: 100%;
		display: flex;
		gap: 1rem;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		box-shadow: var(--box-shadow);
		border: 1px solid var(--p-grey-300);
		padding: 0.75rem;
		border-radius: 0.75rem;
		cursor: pointer;
		&__content {
			display: flex;
			gap: 0.75rem;
			align-items: center;
		}
		&__info {
			display: flex;
			flex-direction: column;
		}
	}
}
</style>
