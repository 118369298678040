<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useWindowScroll } from '@vueuse/core';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Capacitor } from '@capacitor/core';
import {
	PrimeMobileNavigationWrapper,
	PrimeNavigationMobile,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useNavigation } from '@/components/composables/useNavigation';
import { Nav, Platforms } from '@/enums';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { MORE } from '@/locales/constants';

const { t } = useI18n();
const { y: axisY } = useWindowScroll();
const authStore = useAuthStore();
const { isMobileNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();
const { isOpen, navigationClick, items } = useNavigation(Nav.MOBILE);

watch(
	axisY,
	(newValue, oldValue) => {
		// show mobile nav when scrolling on top direction
		if (oldValue && newValue < oldValue) {
			!isMobileNavVisible.value &&
				authStore.setMobileNavigationVisibility(true);
		} else {
			// 64 -- height of the mobile nav
			if (newValue > 64) {
				// hide mobile nav when offset from top position is more than 64px
				isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(false);
			} else {
				// show mobile nav when offset from top position is less than 64px
				!isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(true);
			}
		}
	},
	{ immediate: true }
);

const platform = computed<Platforms>(
	() => Capacitor.getPlatform() as Platforms
);
</script>

<template>
	<PrimeMobileNavigationWrapper
		class="app-mobile-nav"
		:data="items"
		:platform="platform"
		:is-mobile-app="isMobileApp"
		:collapsed="!isMobileNavVisible"
		data-test-id="mobile-nav-wrapper"
	>
		<template #items="{ data }">
			<PrimeNavigationMobile
				v-for="item in data"
				:key="item.id"
				:data="{ ...item, isOpen, modalTitle: t(MORE) }"
				:data-test-id="`mobile-nav-item-${item.id}`"
				@on-modal-close="isOpen = false"
				@navigation-click="navigationClick($event as NavItem)"
			/>
		</template>
	</PrimeMobileNavigationWrapper>
</template>
