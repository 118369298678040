export const SAVE = 'save';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const RESET_PASSWORD = 'reset_password';
export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_MESSAGE = 'confirm_email_message';
export const RESEND = 'resend';
export const EMAIL_REQUIRED = 'email_required';
export const EMAIL_NOT_VALID = 'email_not_valid';
export const PASSWORD_REQUIRED = 'password_required';
export const PASSWORD_MIN_LENGTH = 'password_min_length';
export const POINTS = 'points';
export const SELECT = 'select';
export const SCREENOUT = 'screenout';
export const BONUS = 'bonus';
export const BALANCE = 'balance';
export const BALANCE_REST = 'balance_rest';
export const REWARD_SENT = 'reward_sent';
export const REWARD_RECEIVE_TIME = 'reward_receive_time';
export const OKAY = 'okay';
export const SEND = 'send';
export const ENTER_NEW_PASSWORD = 'enter_new_password';
export const CONFIRM_PASSWORD = 'confirm_password';
export const CONFIRM = 'confirm';
export const CONFIRM_PASSWORD_REQUIRED = 'confirm_password_required';
export const UPDATED_BALANCE = 'updated_balance';
export const SOMETHING_WENT_WRONG = 'something_went_wrong';
export const TRY_AGAIN = 'try_again';
export const REFRESH_PAGE = 'refresh_page';
export const TRANSACTIONS = 'transactions';
export const TRANSACTIONS_EMPTY_TITLE = 'transactions_empty_title';
export const TRANSACTIONS_EMPTY_DESCRIPTION = 'transactions_empty_description';
export const SELECT_REWARDS = 'select_rewards';
export const SEARCH_REWARDS = 'search_rewards';
export const NO_REWARDS_MESSAGE = 'no_rewards_message';
export const CLAIM_REWARD = 'claim_reward';
export const TRANSFER = 'transfer';
export const PREPAID_CARDS = 'prepaid_cards';
export const GIFT_CARDS = 'gift_cards';
export const CHARITY = 'charity';
export const VIEW_MORE_REWARDS = 'view_more_rewards';
export const NO_REWARDS_WITH_NAME = 'no_rewards_with_name';
export const SURVEYS = 'surveys';
export const REWARDS = 'rewards';
export const REWARD = 'reward';
export const DELETE = 'delete';
export const DELETE_ACCOUNT = 'delete_account';
export const DELETE_ACCOUNT_QUESTION = 'delete_account_question';
export const DELETE_ACCOUNT_INFORMATION = 'delete_account_information';
export const NO_REWARDS_HISTORY_TITLE = 'no_rewards_history_title';
export const NO_REWARDS_HISTORY_MESSAGE = 'no_rewards_history_message';
export const COLLECT_POINTS = 'earn_money';
export const DONE = 'done';
export const AMOUNT = 'amount';
export const DATE = 'date';
export const USERNAME_MIN_LENGTH = 'username_min_length';
export const USERNAME_MAX_LENGTH = 'username_max_length';
export const USERNAME_ALLOWED_SYMBOLS = 'username_allowed_symbols';
export const USERNAME_REQUIRED = 'username_required';
export const CHANGE_TIMEZONE = 'change_timezone';
export const CHANGE_EMAIL = 'change_email';
export const ENTER_NEW_EMAIL = 'enter_new_email';
export const NEW_EMAIL = 'new_email';
export const CHANGE_PASSWORD = 'change_password';
export const OLD_PASSWORD = 'old_password';
export const PRIZE_DRAW = 'prize_draw';
export const LVL = 'lvl';
export const LEVEL_UP = 'level_up';
export const JANUARY = 'january';
export const FEBRUARY = 'february';
export const MARCH = 'march';
export const APRIL = 'april';
export const MAY = 'may';
export const JUNE = 'june';
export const JULY = 'july';
export const AUGUST = 'august';
export const SEPTEMBER = 'september';
export const OCTOBER = 'october';
export const NOVEMBER = 'november';
export const DECEMBER = 'december';
export const REFERRAL = 'referral';
export const PROFILE_SURVEYS_COMPLETED = 'profile_surveys_completed';
export const PROFILE_REWARDS_REDEEMED = 'profile_rewards_redeemed';
export const ACTIVITIES = 'activities';
export const HELP = 'help';
export const NO_MORE_ENTRIES = 'no_more_entries';
export const LOADING_MORE = 'loading_more';
export const OTHER_TRANSACTION = 'other_transaction';
export const EMAIL_PREFERENCES = 'email_preferences';
export const EMAIL_SUBS_TITLE = 'email_subs_title';
export const EMAIL_SUBS_SAVE = 'email_subs_save';
export const EMAIL_SUBS_SHOW_ALL = 'email_subs_show_all';
export const EMAIL_SUB_OFFERS = 'email_sub_offers';
export const EMAIL_SUB_REMINDERS = 'email_sub_reminders';
export const EMAIL_SUB_WEEKLY_REPORT = 'email_sub_weekly_report';
export const EMAIL_SUB_ACCOUNT_UPDATES = 'email_sub_account_updates';
export const EMAIL_SUB_SURVEY_INVITATIONS = 'email_sub_survey_invitations';
export const EMAIL_SUB_OFFERS_DESCRIPTION = 'email_sub_offers_description';
export const EMAIL_SUB_REMINDERS_DESCRIPTION =
	'email_sub_reminders_description';
export const EMAIL_SUB_WEEKLY_REPORT_DESCRIPTION =
	'email_sub_weekly_report_description_new';
export const EMAIL_SUB_ACCOUNT_UPDATES_DESCRIPTION =
	'email_sub_account_updates_description2';
export const EMAIL_SUB_SURVEY_INVITATIONS_DESCRIPTION =
	'email_sub_survey_invitations_description';
export const EMAIL_SUBS_UPDATED = 'email_subs_updated';
export const EMAIL_SUBS_NOT_UPDATED = 'email_subs_not_updated';
export const LANGUAGE = 'language';
export const CHANGE_LANGUAGE_TITLE = 'change_language_title';
export const CHANGE_LANGUAGE_SUBTITLE = 'change_language_subtitle';
export const CHANGE_LANGUAGE_MESSAGE = 'change_language_message_new';
export const ACCOUNT = 'account';
export const NEED_NEW_PASSWORD = 'need_new_password';
export const UNLINK_SOCIALS = 'unlink_socials';
export const RESET_PASSWORD_SENT_MESSAGE = 'reset_password_sent_message';
export const RESET_PASSWORD_SENT_DETAILS =
	'reset_password_sent_message-details';
export const RESET_PASSWORD_SENT_SPAM = 'reset_password_sent_message-spam';
export const YOUR_REWARD = 'your_reward';
export const THANK_YOU_FOR_OPINION = 'thank_you_for_opinion';
export const TAKE_SURVEYS_EARN_POINTS = 'take_surveys_earn_points2';
export const TAKE_SURVEYS = 'take_surveys';
export const SELECT_COUNTRY_LANGUAGE_HEADER = 'select_country_language_header';
export const SEARCH_COUNTRY = 'search_country';
export const LOCALIZATION_EMPTY_SEARCH = 'localization_modal_no_items';
export const OTHER_OPTIONS = 'other_options';
export const CONTINUE = 'continue';
export const LOGIN = 'login';
export const SIGN_UP = 'sign_up';
export const ACCEPT_POLICY_TERMS = 'accept_policy_terms';
export const SIGN_UP_INFO = 'sign_up_info';
export const EMAIL_VERIFY_WARNING = 'email_verification_warning';
export const SENT = 'sent';
export const CONTINUE_WITH_SOCIAL = 'continue_with_provider';
export const SOCIAL_ACCOUNT_MESSAGE = 'social_account_message';
export const NOT_YOUR = 'not_your';
export const USE_ANOTHER_ACCOUNT = 'use_another_account';
export const SIGN_UP_SOCIAL: Record<string, string> = {
	google: 'sign_up_google',
	facebook: 'sign_up_facebook',
	apple: 'sign_up_apple',
};
export const ACCOUNT_EXISTS = 'account_exists';
export const LINK_ACCOUNTS_MESSAGE = 'link_account_message';
export const SET_PASSWORD = 'set_password';
export const SET_PASSWORD_MESSAGE = 'set_password_message';
export const CLAIM_EMAIL = 'claim_email';
export const PROCESSING = 'processing';
export const NO_CONNECTION_TITLE = 'no_connection_title';
export const NO_CONNECTION_MESSAGE = 'no_connection_message';
export const RELOAD = 'reload';
export const CODE_REQUIRED = 'code_required';
export const CODE_SENT = 'code_sent';
export const CODE_SENT_MESSAGE = 'code_sent_message';
export const CODE_SENT_QUESTION = 'code_sent_question';
export const RESEND_CODE = 'resend_code';
export const NOT_VERIFIED_CODE = 'not_verified_code';
export const REWARD_IS_ON_WAY = 'reward_is_on_way';
export const CONFIRM_PAYPAL_EMAIL = 'confirm_paypal_email';
export const PAYPAL_EMAIL = 'paypal_email';
export const CONFIRM_PAYPAL_MESSAGE = 'confirm_paypal_message';
export const PROCEED = 'proceed';
export const PASSWORD_NOT_EQUAL = 'password_not_equal';
export const REFUNDED = 'refunded';
export const ERROR = 'error';
export const REFUND = 'refund';
export const SELECT_LANGUAGE = 'select_language';
export const STREAK_SAVED = 'streak_saved';
export const EMAIL_TO_PREVIOUS = 'email_to_previous';
export const CONFIRM_CLAIM_MODAL_TITLE = 'confirm_claim_modal_title';
export const CONFIRM_CLAIM_MODAL_MESSAGE = 'confirm_claim_modal_message';
export const CONFIRM_CLAIM_DELTA = 'confirm_claim_delta';
export const CONFIRM_VIA_EMAIL = 'confirm_via_email';
export const TRANSLATE_MODAL_HEADER = 'translate_modal_header';
export const TRANSLATE_MODAL_TITLE = 'translate_modal_title';
export const TRANSLATE_MODAL_INFO = 'translate_modal_info';
export const TRANSLATE_MODAL_MESSAGE = 'translate_modal_message';
export const OPEN_ACCOUNT_SETTINGS = 'open_account_settings';
export const SAVE_DISCOUNT = 'save_discount';
export const REVOLUT_REWARD_SIGNUP_DESCRIPTION =
	'revolut_reward_signup_description_new';
export const CLICK_TO_SIGNUP = 'click_to_signup';
export const FULL_NAME = 'full_name';
export const TAG = 'tag';
export const FULL_NAME_REQUIRED = 'full_name_required';
export const TAG_REQUIRED = 'tag_required';
export const FULL_NAME_MIN_LENGTH = 'full_name_min_length';
export const FULL_NAME_MAX_LENGTH = 'full_name_max_length';
export const TAG_MIN_LENGTH = 'tag_min_length';
export const TAG_MAX_LENGTH = 'tag_max_length';
export const REVOLUT_CLAIM_DATA = 'revolut_claim_data';
export const ACH_FULL_NAME_CONFIRM = 'confirm_ach_full_name';
export const ACH_FULL_NAME_CONFIRM_PLACEHOLDER =
	'confirm_ach_full_name_placeholder';
export const CONFIRM_ACH_FULL_NAME_MESSAGE = 'confirm_ach_full_name_message';
export const CONFIRM_FULL_NAME = 'confirm_full_name';
export const CHANGE_NAME = 'change_name';
export const ASK_PERMISSIONS_MODAL_HEADER = 'ask_permissions_modal_header';
export const ASK_PERMISSIONS_MODAL_TITLE = 'ask_permissions_modal_title';
export const ASK_PERMISSIONS_MODAL_MESSAGE = 'ask_permissions_modal_message2';
export const ASK_PERMISSIONS_MODAL_IMPORTANT =
	'ask_permissions_modal_important';
export const OFFERWALLS_TITLE = 'offerwalls_title';
export const COMPLETE = 'complete';
export const ALL = 'all';
export const POPULAR = 'popular';
export const FOOD = 'food';
export const GAMING = 'gaming';
export const SHOPPING = 'shopping';
export const ACCOUNT_EXISTS_TITLE = 'account_exist_title';
export const NEW_VERSION_AVAILABLE_TITLE_DOWNLOADING =
	'new_version_available_title_downloading';
export const NEW_VERSION_AVAILABLE_MSG_DOWNLOADING =
	'new_version_available_msg_downloading';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLING =
	'new_version_available_title_installing';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLING =
	'new_version_available_msg_installing';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLED =
	'new_version_available_title_installed';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLED =
	'new_version_available_msg_installed';
export const NEW_VERSION_AVAILABLE_BTN_INSTALLED =
	'new_version_available_btn_installed';
export const NEW_VERSION_AVAILABLE_TITLE_FAILED =
	'new_version_available_title_failed';
export const NEW_VERSION_AVAILABLE_MSG_FAILED =
	'new_version_available_msg_failed2';
export const NEW_VERSION_AVAILABLE_BTN_FAILED =
	'new_version_available_btn_failed2';
export const NEW_VERSION_AVAILABLE_TITLE = 'new_version_available_title';
export const NEW_VERSION_AVAILABLE_MSG = 'new_version_available_msg_store2';
export const NEW_VERSION_AVAILABLE_BTN = 'new_version_available_btn';
export const NEW_VERSION_AVAILABLE_SUCCESS = 'new_version_available_success';
export const SETTINGS = 'settings';
export const TIMEZONE_UPDATE_SUCCESS_MESSAGE =
	'timezone_update_success_message';
export const MORE = 'more';
export const APP_LOGIN_TITLE = 'app_login_title';
export const APP_LOGIN_SUBTITLE = 'app_login_subtitle';
export const APP_LOGIN_FEAT1 = 'app_login_feat1';
export const APP_LOGIN_FEAT2 = 'app_login_feat2';
export const APP_LOGIN_CARD_TITLE = 'app_login_card_title';
export const APP_LOGIN_CARD_SUBTITLE = 'app_login_card_subtitle';
export const NAV_CASHOUT = 'nav_cashout';
export const STATISTICS_TOTAL_EARNINGS = 'statistic_total_earnings';
export const STATISTICS_TITLE = 'statistic_title';
export const LOGOUT = 'logout';
export const WELCOME_MODAL_TITLE_TEXT = 'welcome_modal_title';
export const WELCOME_MODAL_SUBTITLE_TEXT = 'welcome_modal_subtitle';
export const WELCOME_MODAL_MESSAGE_TEXT = 'welcome_modal_message';
export const WELCOME_MODAL_NOTE_TEXT = 'welcome_modal_note';
export const WELCOME_MODAL_ACTION_TEXT = 'welcome_modal_action';
export const APP_REVIEW = 'app_review';
export const APP_REVIEW_TEXT = 'app_review_text';
export const HISTORY_PAGE_TITLE = 'history_page_title';
export const STATUS = 'status';
export const SELECT_CHOICE = 'select_choice';
export const NO_RESULTS = 'select_choice';
export const NEW_VERSION_AVAILABLE_HEADER = 'new_version_available_header';
export const OFFERS = 'offers';
export const YOU_EARNED = 'you_earned';
export const WE_CREDITED_YOU_CURRENCY = 'we_credit_you_currency';
export const WELL_DONE = 'well_done';
export const OFFERS_ACTIVITIES = 'offers_activities';
export const YOU_COMPLETED_OFFER = 'you_completed_offer';
export const OFFER_REWARD_DELAYED = 'offer_reward_delayed';
export const SEARCH = 'search';
export const NO_RESULT_FOUNDED = 'no_result_founded';
