export enum ATT_STATUS_EVENTS {
	ATT_AUTHORIZED = 'test_att_authorized',
	ATT_DENIED = 'test_att_denied',
	ATT_RESTRICTED = 'test_att_restricted',
	ATT_NOT_DETERMINED = 'test_att_not_determind',
}

export const attDebugEvents: Record<string, string> = {
	[ATT_STATUS_EVENTS.ATT_DENIED]: 'iepgao',
	[ATT_STATUS_EVENTS.ATT_AUTHORIZED]: '3wmcmk',
	[ATT_STATUS_EVENTS.ATT_NOT_DETERMINED]: 'uvccs7',
	[ATT_STATUS_EVENTS.ATT_RESTRICTED]: 'x5ttpo',
};
