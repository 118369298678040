import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import {
	PiHomeFill,
	PiTsAccount_balance_wallet,
	PiTsAccount_circle,
	PiCurrencyCircleDollarFill,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';
import { ACCOUNT, NAV_CASHOUT, OFFERS, SURVEYS } from '@/locales/constants';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

export const useNavItems = () => {
	const { t } = useI18n();
	const { isPsOffersEnabled } = storeToRefs(useUserStore());

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiHomeFill,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiCurrencyCircleDollarFill,
			label: t(OFFERS),
			to: USER_ROUTE_NAME.OFFERS,
			isActive: isPsOffersEnabled.value,
			id: USER_ROUTE_NAME.OFFERS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiTsAccount_balance_wallet,
			id: 'cashout',
			label: t(NAV_CASHOUT),
			isActive: true,
			placed: ['desktop', 'mobile'],
			handler: function () {},
		},
		{
			icon: PiTsAccount_circle,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop', 'mobile'],
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items.filter((item) => item.placed.includes(type) && item.isActive);
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
