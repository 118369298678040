import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkContextIdHook,
	checkEmailSubscription,
	checkFeatureFlagsHook,
	checkHistoryTypeHook,
	checkInitializedClaim,
} from '@/router/hooks';
import { FeatureFlags } from '@/enums';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: () => import('@/views/surveys/SurveysView.vue'),
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: () => import('@/views/settings/SettingsView.vue'),
	},
	{
		path: '/reward-history/:type?',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: () => import('@/views/history/HistoryView.vue'),
		beforeEnter: checkHistoryTypeHook,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/confirm-claim',
		name: USER_ROUTE_NAME.CONFIRM_CLAIM,
		component: () => import('@/views/confirm-claim/ConfirmClaimView.vue'),
		beforeEnter: checkInitializedClaim,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/recent-activity',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/verify-email',
		name: USER_ROUTE_NAME.VERIFY_EMAIL,
		component: () => import('@/views/auth/verify-email/VerifyEmailView.vue'),
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		beforeEnter: checkEmailSubscription,
		component: () =>
			import('@/views/email-subscriptions/EmailSubscriptionsView.vue'),
		meta: {
			ignoreAuth: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/offers',
		name: USER_ROUTE_NAME.OFFERS,
		component: () => import('@/views/offers/OffersView.vue'),
		meta: {
			feature: FeatureFlags.PS_OFFERS,
		},
		beforeEnter: checkFeatureFlagsHook,
	},
	{
		path: '/recent-activity-offers',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
	},
];
