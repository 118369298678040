export const DELETE_ACCOUNT_MODAL = 'delete_account_modal';
export const CHANGE_EMAIL_MODAL = 'change_email_modal';
export const CHANGE_PASSWORD_MODAL = 'change_password_modal';
export const CHANGE_TIMEZONE_MODAL = 'change_timezone_modal';
export const REWARDS_MODAL = 'rewards_modal';
export const REWARD_SENT_MODAL = 'reward_sent_modal';
export const NEW_PASSWORD_SENT = 'new_password_sent';
export const CHANGE_LANGUAGE_MODAL = 'change_language_modal';
export const ONE_CLICK_SURVEY_MODAL = 'one_click_survey_modal';
export const SELECT_COUNTRY_LANGUAGE_MODAL = 'select_country_language_modal';
export const SET_USER_PASSWORD_MODAL = 'set_user_password_modal';
export const MOBILE_APP_HERE_MODAL = 'mobile_app_here_modal';
export const CONFIRM_CLAIM_DATA_MODAL = 'confirm_claim_data_modal';
export const CONFIRM_CLAIM_MODAL = 'confirm_claim_modal';
export const GOOGLE_TRANSLATE_MODAL = 'google_translate_modal';
export const ASK_TRACKING_PERMISSIONS_MODAL = 'ask_tracking_permissions_modal';
export const REVOLUT_ANNOUNCEMENT_MODAL = 'revolut_announcement_modal';
export const IOS_BROKEN_APP_MODAL = 'ios_broken_app_modal';
export const DYNAMIC_ANNOUNCEMENT_MODAL = 'dynamic_announcement_modal';
export const UPDATE_AVAILABLE_MODAL = 'update_available_modal';
export const WELCOME_MODAL = 'welcome_modal';
export const APP_REVIEW_MODAL = 'app_review_modal';
export const OFFERWALLS_REWARD_NOTIFICATION_MODAL =
	'offerwalls_reward_notification_modal';
export const OFFERS_PENDING_MODAL = 'offers_pending_modal';
