<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import {
	OKAY,
	WELL_DONE,
	OFFER_REWARD_DELAYED,
	YOU_COMPLETED_OFFER,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { OfferwallTransactionDelayed } from '@/types';
import { transformCurrency } from '@/utils/helpers';
import congratulations from '@/assets/icons/congratulations.svg';
import { useUserStore } from '@/stores/user';

defineProps<{
	options: OfferwallTransactionDelayed;
}>();

const emits = defineEmits<{
	'close-modal': [];
}>();

const { currency } = storeToRefs(useUserStore());
const isDisabled = ref(false);

const onClose = () => {
	isDisabled.value = true;
	emits('close-modal');
};
</script>

<template>
	<div class="one-click-survey">
		<ModalCommonContent>
			<template #header>{{ $t(WELL_DONE) }}</template>
			<template #icon>
				<img
					:src="congratulations"
					alt="earned currency logo"
					class="fade-in logo"
				/>
			</template>
			<template #title>
				<PrimeText weight="600" size="lg">
					{{
						$t(YOU_COMPLETED_OFFER, {
							name: options?.offer_name,
							value: transformCurrency(+options?.money_value!, currency, 2),
						})
					}}
				</PrimeText>
			</template>
			<template #message>
				{{ $t(OFFER_REWARD_DELAYED, { days: options?.credit_delay }) }}
			</template>
			<template #actions>
				<PrimeButton
					full-width
					:label="$t(OKAY)"
					:disabled="isDisabled"
					rounded="rounded"
					@click="onClose"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.one-click-survey {
	:deep(.modal-common-content) {
		.content-icon {
			width: 9.375rem;
			height: 9.375rem;
			margin-bottom: 1.5rem;
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
