<script setup lang="ts">
import type { Component } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineProps<{
	selected: boolean;
	icon: Component | null;
	label: string;
	id: string;
}>();

const modelValue = defineModel<string>({ required: true });
</script>

<template>
	<input
		:id="id"
		v-model="modelValue"
		type="radio"
		:value="id"
		class="sub-category__input"
	/>
	<label
		:for="id"
		class="sub-category__label"
		:class="{ 'category-selected': selected }"
	>
		<PrimeText :weight="selected ? '600' : '500'" size="sm">
			{{ $t(label) }}
		</PrimeText>
		<component
			:is="icon"
			v-if="icon"
			:color="selected ? 'base-primary' : 'text-default'"
		/>
	</label>
</template>

<style lang="scss" scoped>
.sub-category__input {
	display: none;
}

.sub-category__label {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	font-weight: 500;
	padding: 0.375rem 0.75rem;
	justify-content: center;
	gap: 0.25rem;
	border-radius: 3.125rem;
	border: 1px solid var(--p-grey-300);
	background: var(--p-grey-200);
	line-height: 140%;

	&.category-selected {
		border-radius: 3.125rem;
		border: 1px solid var(--p-grey-300);
		background: var(--p-white);
	}
}
</style>
