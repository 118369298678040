import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount } from 'vue';
import type { StatisticItem } from '@/types';
import {
	PiTsAccount_balance_wallet,
	PiTsCredit_card_heart,
	PiCheckCircleFill,
	PiCurrencyCircleDollarFill,
} from '@primeinsightsgroupllc/prime-icons';
import {
	OFFERS_ACTIVITIES,
	PROFILE_REWARDS_REDEEMED,
	PROFILE_SURVEYS_COMPLETED,
	STATISTICS_TOTAL_EARNINGS,
} from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { USER_ROUTE_NAME } from '@/constants/routes';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

export const useStatistics = () => {
	const { t } = useI18n();
	const userStore = useUserStore();

	const { userStats, isPsOffersEnabled } = storeToRefs(userStore);

	onBeforeMount(async () => {
		await userStore.fetchUserStats();
	});

	const isProfileStatsLoaded = computed(() => !!userStats.value);

	const statistics = computed<StatisticItem[]>(() => {
		if (!userStats.value) return [];

		const { cash, surveys, reward } = userStats.value;

		return [
			{
				icon: PiTsAccount_balance_wallet,
				title: transformCurrency(cash.total, cash.currency, 2),
				details: t(STATISTICS_TOTAL_EARNINGS),
				route: {
					name: USER_ROUTE_NAME.REWARD_HISTORY,
					params: { type: 'transactions' },
				},
				visible: true,
			},
			{
				icon: PiCheckCircleFill,
				title: String(surveys.completed),
				details: t(PROFILE_SURVEYS_COMPLETED),
				route: {
					name: USER_ROUTE_NAME.RECENT_ACTIVITY,
				},
				visible: true,
			},
			{
				icon: PiTsCredit_card_heart,
				title: localizePoints(reward.total),
				details: t(PROFILE_REWARDS_REDEEMED),
				route: {
					name: USER_ROUTE_NAME.REWARD_HISTORY,
					params: { type: 'rewards' },
				},
				visible: true,
			},
			{
				icon: PiCurrencyCircleDollarFill,
				details: t(OFFERS_ACTIVITIES),
				route: {
					name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
					params: { type: 'rewards' },
				},
				visible: isPsOffersEnabled.value,
			},
		];
	});

	return {
		statistics,
		isProfileStatsLoaded,
	};
};
