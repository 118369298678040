import { useLocalStorage } from '@vueuse/core';

export const cidStorage = useLocalStorage<string>('cid', '');
export const aIDStorage = useLocalStorage<string>('a-id', null);
export const platformStorage = useLocalStorage<string>('platform', '');

export const deviceStorage = useLocalStorage<string>('device', '');
export const oneClickSurveyRewardStorage = useLocalStorage<string>(
	'one-click-reward',
	''
);
export const signatureLoginActionStorage = useLocalStorage<string>(
	'signature-login-action',
	null
);
export const maidStorage = useLocalStorage<string>('maid', '');
// TODO: remove it when all users will use our own chat
export const showPrimeChat = useLocalStorage<boolean>('show-prime-chat', false);

export const sslhStorage = useLocalStorage<string>('sslh-invite', '');

export const isInAppBrowserStorage = useLocalStorage<boolean>(
	'in-app-browser',
	false
);

export const showAlerts = useLocalStorage<boolean>('show-alerts', false);

export const appLogStorage = useLocalStorage<boolean>('a-l', false);
export const appKeyStorage = useLocalStorage<number>('app-key', 0);
