import { Echo } from '@/utils/echo';
import { ref, onUnmounted, watch, computed } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE } from '@/locales/constants';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type {
	UserBalanceUpdateInfo,
	FeatureFlagsData,
	OfferwallRewardEvent,
	OfferwallTransactionDelayed,
} from '@/types';
import { TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useMessagesStore } from '@/stores/messages';
import {
	OFFERS_PENDING_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
} from '@/constants/modals';
import { MessageChannels } from '@/enums';

export const useAppPusherEventListener = (): {
	initPusherConnection: () => Promise<void>;
	removePusherConnection: () => void;
} => {
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const userStore = useUserStore();
	const appStore = useAppStore();
	const { createNewModal } = useModalStorage();
	const messagesStore = useMessagesStore();
	const userAndToken = computed(() => userId.value + userAuthToken.value);

	const removePusherConnection = () => {
		echo.removeConnections();
	};

	const initPusherConnection = async () => {
		if (!userId.value || !userAuthToken.value) return;
		channelName.value = `user-notifications-${userId.value}`;
		if (echo.isCurrentConnection(channelName.value, userAuthToken.value)) {
			return;
		}
		removePusherConnection();

		try {
			await echo.createConnection(userAuthToken.value);
		} catch (error) {
			console.error('Error while creating WebSocket connection:', error);
		}

		window.Echo.private(channelName.value)
			.listen(
				// Balance update
				'.balance-updated',
				async (event: UserBalanceUpdateInfo) => {
					await userStore.fetchUserStats();
					await userStore.fetchUserData();
					userStore.setCollectedCoins(event.new_balance);
					if (!event.is_silent) {
						notify({ body: t(UPDATED_BALANCE) });
					}
				}
			)
			.listen(
				'.offerwall_transaction_completed',
				async (event: OfferwallRewardEvent) => {
					await userStore.fetchUserStats();
					await userStore.fetchUserData();
					userStore.setCollectedCoins(event.new_balance);
					createNewModal(OFFERWALLS_REWARD_NOTIFICATION_MODAL, {
						...event,
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () =>
							messagesStore.readNotification(event.notification_id),
					});
				}
			)
			.listen(
				'.offerwall_transaction_delayed',
				(event: OfferwallTransactionDelayed) => {
					createNewModal(OFFERS_PENDING_MODAL, {
						...event,
						shared: true,
						onClose: () =>
							messagesStore.readNotification(event.notification_id),
					});
				}
			)
			.listen('.feature-flags-update', (event: FeatureFlagsData) => {
				userStore.updateFeatures(event);
			})

			.listen(
				'.gtm_track_event',
				async ({
					event_name,
					event_name_adjust,
					params,
				}: {
					event_name: TrackingEvents;
					event_name_adjust: string;
					params: Record<string, string>;
				}) => {
					appStore.addLog(
						`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
					);
					await appStore.trackEvent(event_name, params, event_name_adjust);
				}
			);
	};

	watch(
		userAndToken,
		async () => {
			await initPusherConnection();
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		removePusherConnection();
	});

	return {
		initPusherConnection,
		removePusherConnection,
	};
};
