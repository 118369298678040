import type { Component } from 'vue';
import {
	APP_REVIEW_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_LANGUAGE_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	CONFIRM_CLAIM_MODAL,
	DELETE_ACCOUNT_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	GOOGLE_TRANSLATE_MODAL,
	NEW_PASSWORD_SENT,
	ONE_CLICK_SURVEY_MODAL,
	REWARD_SENT_MODAL,
	REWARDS_MODAL,
	SELECT_COUNTRY_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	UPDATE_AVAILABLE_MODAL,
	WELCOME_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	OFFERS_PENDING_MODAL,
} from '@/constants/modals';
import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import OneClickSurveyModal from '@/components/modals/OneClickSurveyModal.vue';
import SelectCountryLanguageModal from '@/components/modals/SelectCountryLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import ConfirmPaypalEmailModal from '@/components/modals/ConfirmClaimDataModal.vue';
import AppReviewModal from '@/components/modals/AppReviewModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import GoogleTranslateModal from '@/components/modals/GoogleTranslateModal.vue';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import WelcomeModal from '@/components/modals/WelcomeModal.vue';
import OfferwallRewardNotificationModal from '@/components/modals/notifications/OfferwallRewardNotificationModal.vue';
import OffersPendingModal from '@/components/modals/notifications/OffersPendingModal.vue';

export const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[REWARDS_MODAL]: RewardsModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[ONE_CLICK_SURVEY_MODAL]: OneClickSurveyModal,
	[SELECT_COUNTRY_LANGUAGE_MODAL]: SelectCountryLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmPaypalEmailModal,
	[APP_REVIEW_MODAL]: AppReviewModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[GOOGLE_TRANSLATE_MODAL]: GoogleTranslateModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[WELCOME_MODAL]: WelcomeModal,
	[OFFERWALLS_REWARD_NOTIFICATION_MODAL]: OfferwallRewardNotificationModal,
	[OFFERS_PENDING_MODAL]: OffersPendingModal,
};
